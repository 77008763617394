import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 解决：Uncaught (in promise) NavigationDuplicated;
// let originalPush = VueRouter.prototype.push; // 先保存一份 VueRouter.prototype.push方法
// let originalRepace = VueRouter.prototype.replace;

// VueRouter.prototype.push = function push(location) {
//     // call(); 篡改上下文    catch(); 捕获异常 ****
//     return originalPush.call(this, location).catch(e => e);
// }
// VueRouter.prototype.replace = function replace(location) {
//     return originalRepace.call(this, location).catch(e => e);
// }

const routes = [
  {
    path: "/",
    component: () => import("@/view/home.vue"),
    meta: {
      title: "【杭州写字楼出租|杭州办公室出租|办公楼出租网/租赁】-东业控股集团",
      keywords:
        "写字楼出租,杭州写字楼出租,杭州办公室租赁,杭州办公楼出租,东业集团",
      description:
        "「东业控股集团」是杭州一家以杭州写字楼出租、杭州办公室出租、杭州办公楼出租/租赁、产业园区招商、创业园和创意园、众创空间和青年众创社区租赁为主营业务的集团化公司，提供定制化选址服务。",
      keepAlive: false,
    },
  },
  {
    path: "/search/list",
    component: () => import("@/view/search/list.vue"),
    meta: {
      title:
        "【杭州写字楼租赁|杭州科创电商产业园出租|办公楼出租信息】-东业官网",
      keywords:
        "杭州写字楼出租,杭州产业园出租,杭州创业园,杭州科技园,杭州电商园",
      description:
        "杭州东业园区写字楼出租网提供真实的杭州写字楼租赁,杭州产业园出租,创业园,科技园,电商园,创业孵化器出租/租赁,帮客户找到满意房源等服务。更多杭州园区、写字楼联合办公室出租信息，欢迎登入东业园区写字楼官网！",
      keepAlive: true,
    },
  },
  {
    path: "/search/building",
    name: "building",
    component: () => import("@/view/search/building.vue"),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/search/buildingmap",
    name: "building",
    component: () => import("@/view/search/buildingmap.vue"),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/news/list",
    component: () => import("@/view/news/list.vue"),
    meta: {
      title: "杭州写字楼|办公室|办公楼|园区最新资讯/新闻信息-东业官网",
      keywords: "写字楼资讯,办公室资讯,办公楼资讯,杭州园区资讯",
      description:
        "杭州东业园区写字楼网为您提供杭州写字楼、办公室、园区办公楼最新资讯，了解更多写字楼出租/租赁、招商信息，欢迎登入东业写字楼网！",
      keepAlive: false,
    },
  },
  {
    path: "/enterprise",
    component: () => import("@/view/enterprise/enterprise.vue"),
    meta: {
      title: "杭州园区/写字楼入驻优秀企业列表|杭州园区写字楼出租-东业官网",
      keywords: "杭州园区入驻,杭州园区入驻企业,杭州写字楼入驻公司",
      description:
        "东业企业频道，提供最新入驻企业信息，为企业搭建一个沟通、合作平台。杭州园区租赁、租用办公室，租赁写字楼，到东业官网。",
      keepAlive: false,
    },
  },
  {
    path: "/service",
    component: () => import("@/view/service.vue"),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/joinus",
    component: () => import("@/view/joinus/index.vue"),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/joinus/position/detail",
    component: () => import("@/view/joinus/detail.vue"),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/about/index",
    component: () => import("@/view/about/index.vue"),
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/news/detail",
    component: () => import("@/view/news/detail.vue"),
    meta: {
      keepAlive: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
