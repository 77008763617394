import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vueMiniPlayer from "vue-mini-player";
import "vue-mini-player/lib/vue-mini-player.css";
Vue.use(vueMiniPlayer);
// import axios from 'axios'
// Vue.prototype.$axios = axios
// Vant
import { Loading, Popup } from "vant";
// import 'video.js/dist/video-js.css'
// import videojs from 'video.js'
import { Swipe, SwipeItem } from "vant";
import { Tab, Tabs } from "vant";
import { Collapse, CollapseItem } from "vant";
import { DropdownMenu, DropdownItem } from "vant";
import { Toast } from "vant";
import "vant/es/toast/style";
import { Dialog } from "vant";
import BaiduMap from "vue-baidu-map";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { Overlay } from "vant";
import Meta from "vue-meta";

Vue.use(Toast);
Vue.use(Meta);
Vue.use(Overlay);
Vue.use(ElementUI);
Vue.use(BaiduMap, {
  ak: "9GXuxWX7vWLYGyhH5sTP0IlAp1iuy8pH", //  在此输入你自己的百度地图ak
});
Vue.use(Dialog);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Popup);
Vue.use(Loading);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.config.productionTip = false;
// 百度统计代码
var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?a86ec0340e88b60fcb0dde8be1f1f73f";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

router.beforeEach((to, from, next) => {
  if (Object.keys(to.meta).length > 0 && to.matched.length > 0) {
    let this_meta = to.matched[to.matched.length - 1].meta;
    console.log(this_meta, "---this_meta---");
    if (this_meta.title) document.title = this_meta.title;
    let head = document.getElementsByTagName("head");
    let meta_keyword = document.createElement("meta");
    if (document.querySelector('meta[name="keywords"]')) {
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", this_meta.keywords);
    } else {
      meta_keyword.setAttribute("name", "keywords");
      meta_keyword.setAttribute("content", this_meta.keywords);
      head[0].appendChild(meta_keyword);
    }
    let meta_description = document.createElement("meta");
    if (document.querySelector('meta[name="description"]')) {
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", this_meta.description);
    } else {
      meta_description.setAttribute("name", "description");
      meta_description.setAttribute("content", this_meta.description);
      head[0].appendChild(meta_description);
    }
  }
  // if (to.path == "/search/buildingmap" && from.path == "/search/building") {
  //   from.meta.keepAlive = true; // 让 列表页 缓存，即不刷新
  //   console.log("from.meta.keepAlive", from);
  // }
  // if (from.path == "/search/list") {
  //   from.meta.keepAlive = true;
  // } else {

  // }
  if (_hmt) {
    if (to.path) {
      _hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }

  next();
});

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
